import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Your Go-to <span class="h1-span">UX Audit Agency</span>`,
    content:
      "Experience the difference with our dedicated UX audit agency. We meticulously assess designs, provide invaluable insights, and optimize usability for a seamless user experience.",
  };

  const commonContent6 = {
    title: "Our User Experience <br /> Audit Services",
    para: "Our website UX audit company will elevate your online presence and help you outpace your competition. With our comprehensive website user experience audit services, we help you uncover usability insights and drive optimum performance.",
    img: ["what-service-image-designaudit.webp"],
    imageTitle: "Design Audit",
    imageAlt: "ux audit services",
    content: [
      {
        title: "Website UX Audit",
        text: "With our custom-design audit services, we offer functional & interactive digital solutions to address your specific needs.",
      },
      {
        title: "Mobile Application UX Audit",
        text: "We conduct an in-depth analysis to provide recommendations for exceptional mobile usability.",
      },
      {
        title: "Web App UX Audit",
        text: "We optimize your web app to provide insights for streamlining its functions and improving user interactions.",
      },
      {
        title: "E-Commerce Design Audit",
        text: "We reveal opportunities to boost your e-commerce platform by enhancing user journey and driving conversions.",
      },
      {
        title: "Usability Testing",
        text: "We use real-user feedback to improve your digital product, fine-tune the design, and improve its functionality.",
      },
      {
        title: "Responsive Design Assessment",
        text: "We conduct comprehensive assessments to ensure cross-device compatibility and responsiveness.",
      },
    ],
  };

  const commonContent = {
    title: "Industry Revolutionised by <br />Our UX Audit Agency",
    para: "Our UX audit services uncover usability issues, pinpoint enhancement opportunities, and provide insights to optimize the user journey for all industries, empowering them to elevate their digital experiences and drive greater engagement.",
    img: ["who-needs-designaudit.webp"],
    imageTitle: "Website Design Audit",
    imageAlt: "website design audit",
    content: [
      {
        title: "Marketing Agencies",
        text: "Companies who want to optimize their user experiences for effective campaigns, refine marketing strategies, and get insights on the target audience.",
      },
      {
        title: "E-commerce Companies",
        text: "Companies that look to enhance user satisfaction, maximize online presence, grow business, and stay ahead in an online marketplace.",
      },
      {
        title: "EdTech Companies",
        text: "Companies that want to offer an intuitive and seamless user experience to drive better learning outcomes and attract more users.",
      },
      {
        title: "Hospitality Industry",
        text: "Companies who aim to drive positive reviews and recommendations by optimizing their digital platforms for seamless user experience.",
      },
    ],
  };

  const commonContent2 = {
    title: "Benefits of website design audit on business",
    para: "A website UX audit company thoroughly evaluates your design elements, interaction flows, and user journey to offer actionable recommendations for improving usability and driving business success.",
    img: ["what-are-benefits-designaudit.webp"],
    imageTitle: "UX Audit Benefits",
    imageAlt: "website user experience audit",
    content: [
      {
        title: "Risk Mitigation",
        text: "UX design audit mitigates risk by identifying usability issues, offering suggestions to reduce user frustration, and enhancing customer satisfaction.",
      },
      {
        title: "Design System Optimization",
        text: "Design Audit assesses the effectiveness and streamlines the design elements for optimum efficiency and optimization of the design system.",
      },
      {
        title: "Improved User Experience",
        text: "A website UX audit recognizes usability issues and provides insights into optimizing design elements to offer a seamless and intuitive user experience.",
      },
      {
        title: "Increased Conversion Rates",
        text: "UX Design Audit enhances the overall usability and visuals of the design by offering a compelling experience leading to increased conversions.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why choose Octet for <br /> UX Audit Services?",
    linkText: ``,
    para: "We use a user-centric approach to uncover insights, identify opportunities, & provide recommendations to elevate your website design & user experience. The team at our UX audit agency leverages user-centered design to create resonant online experiences.",
    img: ["why-opt-for-designaudit.webp"],
    imageTitle: "User Experience Audit Service",
    imageAlt: "user experience audit service",
    content: [
      {
        title: "Industrial Expertise",
        text: "We have a team of industrial experts who provide a comprehensive analysis and recommendations to meet your unique industrial challenges.",
      },
      {
        title: "Factual Insights",
        text: "We evaluate your designs based on objective analysis, data-driven research, and best industry practices to offer evident insights.",
      },
      {
        title: "User-Oriented Focus",
        text: "We focus on the user’s perspective for our analysis to uncover insights and drive improvements to enhance their satisfaction.",
      },
      {
        title: "Prompt Evaluation",
        text: "We can quickly evaluate your digital product's strengths and weaknesses to make informed decisions and take immediate action.",
      },
      {
        title: "Comprehensive Approach",
        text: "We consider all aspects of user experience and thoroughly analyze your digital product’s user experience.",
      },
    ],
  };
  const commonContent4 = {
    title: "Our Website Design Audit <br /> Approach to Transform UX",
    para: "Our website UX audit company comprehensively assesses your digital product, identifies areas for improvement, and offers detailed reports and recommendations to elevate your user experience.",
    img: ["how-we-conduct-designaudit.webp"],
    imageTitle: "how-we-conduct-designaudit",
    imageAlt: "How we process design audit",
    content: [
      {
        title: "1. Understand the goals",
        text: "We first understand the product and business goals to form a context to evaluate the design.",
      },
      {
        title: "3. Report Findings",
        text: "We then comprehensively present the analysis results for management to take action.",
      },
      {
        title: "2. Analyze and conduct tests",
        text: "We find usability inconsistencies and logical gaps to compare them against accepted usability principles.",
      },
      {
        title: "4. Suggest Improvements",
        text: "After the management approves it, we will implement the new design suggestions to improve the product.",
      },
    ],
  };
  const commonContent5 = {
    title: "ROI from Investing in <br /> UX Audit Agency",
    para: "Investing in a website UX audit company uncovers usability issues, offers actionable recommendations, and enhances overall user experience, resulting in higher engagement and conversions.",
    content: [
      {
        title: "80% of Issues Fix",
        text: "Companies that conduct a comprehensive evaluation can fix design issues by up to 80% and achieve better outcomes.",
      },
      {
        title: "83% Consumer Willingness",
        text: "Companies that invest to improve their design get more consumers, leading to higher conversions.",
      },
      {
        title: "76% Preferred good UX",
        text: "Companies invest in design audits because 76% of the users prefer brands that provide an excellent user experience.",
      },
    ],
    desc: "*These data are from public sources, we do not claim to owe these stats.",
  };
  const cta4 = {
    title: "Interested in UX <span class='h1-span'>Design Audit?</span>",
  };
  const cta = {
    title: "Discover the True Potential of <br />Your Website Design with Us!",
  };
  const cta5 = {
    title: "Looking for a quick <br /> website design audit?",
  };
  const cta2 = {
    title: "Opt for Our User <br />Experience Audit Services",
  };
  const cta3 = {
    title: "Turn Insights into Success: <br /> Our UX Audit Services!",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    // {
    //   title: "Vet IT",
    //   desc: "A Cloud-Based practice management software",
    //   Industry: "HealthTech",
    //   link: '/project/vet-clinic',
    //   WhatWeDid: [
    //     {
    //       title: 'Design Audit',
    //       link: '/design-audit/'
    //     },
    //     {
    //       title: 'UI UX Designing',
    //       link: '/ui-ux-designing/'
    //     },
    //     {
    //       title: 'UI Development',
    //       link: '/ui-development/'
    //     }
    //   ],
    //   images: ["vet-it.webp", "vet-it-2.webp"],
    // },
    {
      title: "Landscape Pro",
      desc: "A Property Management Platform",
      Industry: "SaaS",
      link: "/project/landscape-pro/",
      firstAlt: "website ux audit",
      firstTitle: "Landing Page",
      secondAlt: "ux audit agency",
      secondTitle: "All Invoices",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      images: ["landscape-pro.webp", "landscape-pro-2.webp"],
    },
  ];

  const Questions = [
    "How can a website UX audit company benefit my business?",
    "How do you approach a website design audit project?",
    "What are the factors you assess during the design audit?",
    "Can you conduct a remote website design audit?",
    "When should I consider getting a UX design audit?",
    "What is the expected duration of a user experience audit?",
    "What deliverables can I expect at the end of the design audit?",
  ];
  const clientsTitle = "Companies who trusted <br/> our UX Audit Services";
  const faqDes = `We understand that you might want to know more about the benefits and outcomes of our user experience audit services. Hence, we have curated common questions asked by our clients. However, if you still have any queries, please <a href='/contact-us/'>contact us</a>. `;
  const faqData = [
    {
      para: [
        `A UX audit agency can identify your digital product's usability issues and key improvement areas, resulting in enhanced user experience.`,
        `They offer insightful information about your target audience, allowing you to improve your design and marketing tactics to satisfy user requirements.`,
        `You also gain a competitive edge, foster brand loyalty, and promote long-term business success.`,
      ],
    },
    {
      para: [
        `As a leading UX audit agency, we systematically and comprehensively understand your project requirements, target audience, and business goals.`,
        `We thoroughly examine your design components, user interface, etc., to identify the key areas for improvement.`,
        `Then, we offer practical suggestions and a roadmap to improve your design and user experience. We will work with you to assist you throughout the project.`,
      ],
    },
    {
      para: [
        `We evaluate various elements during a design audit to provide a thorough evaluation of your digital product. These elements consist of-`,
      ],
      list: [
        `<span>Visual Design </span> - We assess design aesthetics, typography, color schemes, and overall visual appeal to ensure your design is consistent with your brand identity.`,
        `<span>User Interface</span> -  We evaluate your UI elements' consistency, clarity, and effectiveness to ensure simple and seamless interactions.`,
        `<span>Interaction Design</span> - We analyze the usability, user flow, and intuitiveness of the user experience within your digital product’s interface.`,
        `<span>Content Strategy</span> -  We assess the quality and relevancy of your content to ensure that your message effectively communicates with the users.`,
        `<span>Accessibility</span> - We evaluate your design's accessibility to make sure it complies with applicable laws and regulations and can be used by people with impairments.`,
        `<span>Performance</span> -  We evaluate your digital product's loading speed, responsiveness, and scalability to offer a seamless experience.`,
        `<span>User Feedback</span> -  We consider qualitative and quantitative feedback to gain the user's perspective, problems, and recommendations.`,
      ],
    },
    {
      para: [
        `Yes, we can conduct remote design audits using various collaboration tools. Our process ensures clear communication and delivers actionable recommendations to enhance your user experience.`,
      ],
    },
    {
      para: [
        `A design audit can help you find and fix usability problems, enhance the user experience, and ensure your digital product meets user expectations and business objectives.`,
        `Hence, you should consider obtaining a design audit if you're launching a new product or redesigning an existing one, getting regular user feedback about problems, seeing KPIs decline, trying to gain a competitive edge, or growing or expanding your firm.`,
      ],
    },
    {
      para: [
        `The duration of the UX design audit depends on the project's complexity and scope.`,
        `Various factors influence the duration of the audit, such as the size of the digital product, the number of screens that must be audited, the depth of the analysis required, etc. However, it can range from a few days to several weeks.`,
        `At our website ux audit company, we work closely to understand and fulfill your audit requirements. We aim to ensure a thorough and efficient audit process while meeting your timeline.`,
      ],
    },
    {
      para: [
        `We provide a comprehensive report that summarizes the findings, identified usability issues, and provides actionable recommendations. This report will help you focus on the key improvement areas.`,
        `Depending on your project requirements, you may also receive design guidelines, wireframes, or mockups illustrating the proposed changes.`,
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
           {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "How can a website UX audit company benefit my business?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A UX audit agency can identify your digital product's usability issues and key improvement areas, resulting in enhanced user experience. 
          
          They offer insightful information about your target audience, allowing you to improve your design and marketing tactics to satisfy user requirements. 
          
          You also gain a competitive edge, foster brand loyalty, and promote long-term business success."
              }
            },{
              "@type": "Question",
              "name": "How do you approach a website design audit project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "As a leading UX audit agency, we systematically and comprehensively understand your project requirements, target audience, and business goals. 
          
          We thoroughly examine your design components, user interface, etc., to identify the key areas for improvement. 
          
          Then, we offer practical suggestions and a roadmap to improve your design and user experience. We will work with you to assist you throughout the project."
              }
            },{
              "@type": "Question",
              "name": "What are the factors you assess during the design audit?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We evaluate various elements during a design audit to provide a thorough evaluation of your digital product. These elements consist of-
          Visual Design
          We assess design aesthetics, typography, color schemes, and overall visual appeal to ensure your design is consistent with your brand identity. 
          User Interface
          We evaluate your UI elements' consistency, clarity, and effectiveness to ensure simple and seamless interactions. 
          Interaction Design
          We analyze the usability, user flow, and intuitiveness of the user experience within your digital product’s interface. 
          Content Strategy
          We assess the quality and relevancy of your content to ensure that your message effectively communicates with the users. 
          Accessibility
          We evaluate your design's accessibility to make sure it complies with applicable laws and regulations and can be used by people with impairments.
          Performance
          We evaluate your digital product's loading speed, responsiveness, and scalability to offer a seamless experience. 
          User Feedback
          We consider qualitative and quantitative feedback to gain the user's perspective, problems, and recommendations."
              }
            },{
              "@type": "Question",
              "name": "Can you conduct a remote website design audit?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we can conduct remote design audits using various collaboration tools. Our process ensures clear communication and delivers actionable recommendations to enhance your user experience."
              }
            },{
              "@type": "Question",
              "name": "When should I consider getting a UX design audit?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A design audit can help you find and fix usability problems, enhance the user experience, and ensure your digital product meets user expectations and business objectives.
          
          Hence, you should consider obtaining a design audit if you're launching a new product or redesigning an existing one, getting regular user feedback about problems, seeing KPIs decline, trying to gain a competitive edge, or growing or expanding your firm."
              }
            },{
              "@type": "Question",
              "name": "What is the expected duration of a user experience audit?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The duration of the UX design audit depends on the project's complexity and scope. 
          
          Various factors influence the duration of the audit, such as the size of the digital product, the number of screens that must be audited, the depth of the analysis required, etc. However, it can range from a few days to several weeks. 
          
          At our website ux audit company, we work closely to understand and fulfill your audit requirements. We aim to ensure a thorough and efficient audit process while meeting your timeline."
              }
            },{
              "@type": "Question",
              "name": "What deliverables can I expect at the end of the design audit?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We provide a comprehensive report that summarizes the findings, identified usability issues, and provides actionable recommendations. This report will help you focus on the key improvement areas.
          
          Depending on your project requirements, you may also receive design guidelines, wireframes, or mockups illustrating the proposed changes."
              }
            }]
          }          
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta4} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} lgtextClass="lg:text-[52px]" />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta5} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
                Our UX Audit Case Studies
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials
            title={"Shaping Design Audit <span class='h1-span'>Excellence for Clients</span>"}
          />
          <Cta data={cta2} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="UX Audit Agency | Website Design Audit Services"
    description="Our website design and user experience audit services analyze design elements and advise on key areas of improvement. Let us optimize your online space."
  />
);
